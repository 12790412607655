<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  defineEmits,
  watch,
  computed,
} from "vue";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import ContentTypeBuilder from "@/services/ContentManager/contentManager.service";
import { useToast } from "vue-toast-notification";
import customIcons from "@/assets/map-marker-png.png";
import DateTimePicker from "../DateTimePicker.vue";
import { useStore } from "vuex";

const mapContainer = ref(null);
let map = null; // Regular variable for map
let currentMarker = null; // Regular variable for currentMarker
const searchValue = ref("");
const contentManagerService = new ContentTypeBuilder();
const currentPointer = ref({ lat: "22.3101277", lon: "73.1567104" });
const toast = useToast();
const datePickerValue = ref(null);
const showDateModal = ref(false);

const emit = defineEmits([
  "update:modelValue",
  "update:options",
  "update:contentExpiration",
]);

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    default: "text",
  },
  modelValue: {
    type: [String, Boolean, Array, Object],
    default: undefined,
  },
  label: {
    type: String,
    default: "Label comes here",
  },
  required: {
    type: Boolean,
    default: false,
  },
  minLength: {
    type: Number,
    default: null,
  },
  maxLength: {
    type: Number,
    default: null,
  },
  placeholder: {
    type: String,
    default: "Enter here...",
  },
  customComponent: {
    type: Object,
    default: null,
  },
  pattern: {
    type: String,
    default: null,
  },
  options: {
    type: Array,
    default: [],
  },
  isGlobal: {
    type: Boolean,
  },
  defaultValue: {
    type: Array,
  },
  contentExpiration: {
    type: Boolean,
  },
  contentExpirationValue: {
    type: Date,
  },
});

const store = useStore();

const items = computed(() => {
  return store.getters.items;
});

const currentVersionId = computed(() => {
  return store.getters.currentVersionId;
});

watch(currentVersionId, (newVal) => {
  let tempData = {};
  console.log(newVal);

  if (items.value[0].latestPublish != items.value[0].draftData?._id)
    tempData = items.value[0].draftData?.data[props.id];

  let lat = tempData.lat || 51.505;
  let lon = tempData.lon || -0.09;

  console.log(lat, lon);

  map.setView([lat, lon], 12);

  const customIcon = L.icon({
    iconUrl: customIcons,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  if (currentMarker) {
    map.removeLayer(currentMarker);
  }

  currentMarker = L.marker([lat, lon], { icon: customIcon })
    .addTo(map)
    .bindPopup("Marker")
    .openPopup();
});

onMounted(async () => {
  // currentPointer.value = props.modelValue
  //   ? props.modelValue
  //   : currentPointer.value;

  currentPointer.value =
    items.value[0].latestPublish != items.value[0].draftData?._id
      ? items.value[0].draftData?.data[props.id]
      : (currentPointer.value = props.modelValue
          ? props.modelValue
          : currentPointer.value);

  // Initialize the map
  if (mapContainer.value) {
    map = L.map(mapContainer.value).setView([51.505, -0.09], 12);

    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png").addTo(
      map
    );

    // Custom marker icon
    const customIcon = L.icon({
      iconUrl: customIcons,
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
    });

    // Add the initial marker at currentPointer
    currentMarker = L.marker(
      [currentPointer.value.lat, currentPointer.value.lon],
      { icon: customIcon }
    )
      .addTo(map)
      .bindPopup("Marker")
      .openPopup();

    map.setView([currentPointer.value.lat, currentPointer.value.lon], 12);

    // Handle click event on the map
    map.on("click", async (event) => {
      const { lat, lng } = event.latlng;

      // Update the current pointer
      const iframe = await generateMapIframe(lat, lng);
      currentPointer.value = { lat, lon: lng, iframe: iframe };

      // Remove the old marker if it exists
      if (currentMarker) {
        map.removeLayer(currentMarker);
      }

      // Add a new marker
      currentMarker = L.marker([lat, lng], { icon: customIcon })
        .addTo(map)
        .bindPopup(`You clicked at ${lat.toFixed(2)}, ${lng.toFixed(2)}`)
        .openPopup();

      // Emit updated model value

      if (items.value[0] == items.value.draftData?._id)
        emit("update:modelValue", currentPointer.value);
      else {
        items.value[0].draftData.data[props.id] = currentPointer.value;
      }
    });
  } else {
    console.error("Map container not found.");
  }

  if (props.contentExpirationValue) {
    let mongoDate = new Date(props.contentExpirationValue);
    datePickerValue.value = new Date(mongoDate.toLocaleString());
  }
});

// Cleanup map on unmount
onBeforeUnmount(() => {
  if (map) {
    map.remove();
  }
});

watch(datePickerValue, (newVal) => {
  emit("update:contentExpiration", newVal);
});

const openDateModal = (event) => {
  event.preventDefault();
  showDateModal.value = true;
};

const closeDateModal = async (date) => {
  showDateModal.value = false;
  datePickerValue.value = date;
};

const searchPlace = async (e) => {
  e.preventDefault();

  const result = await contentManagerService.searchLocation(searchValue.value);

  if (result.data[0][0] === "No data found") {
    toast.error("No data found for that location!");
  } else {
    let coordinates = {
      lon: result.data[0].lon,
      lat: result.data[0].lat,
    };

    coordinates.iframe = await generateMapIframe(
      coordinates.lat,
      coordinates.lon
    );

    // Custom marker icon
    const customIcon = L.icon({
      iconUrl: customIcons,
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32],
    });

    currentPointer.value = coordinates;

    // Remove the old marker if it exists
    if (currentMarker && map) {
      map.removeLayer(currentMarker);
    }

    // Add the new marker based on search result
    if (map) {
      currentMarker = L.marker([coordinates.lat, coordinates.lon], {
        icon: customIcon,
      })
        .addTo(map)
        .bindPopup(searchValue.value)
        .openPopup();
    }

    map.setView([currentPointer.value.lat, currentPointer.value.lon], 12);

    // Emit updated model value
    if (items.value[0] == items.value.draftData?._id)
      emit("update:modelValue", currentPointer.value);
    else {
      items.value[0].draftData.data[props.id] = currentPointer.value;
    }
  }
};

async function generateMapIframe(
  lat,
  lon,
  type = "google",
  width = "600",
  height = "450",
  zoom = 14
) {
  let iframeSrc;

  if (type === "google") {
    iframeSrc = `https://www.google.com/maps?q=${lat},${lon}&hl=es;z=${zoom}&output=embed`;
  } else if (type === "openstreetmap") {
    const bbox = `${lon - 0.005},${lat - 0.005},${lon + 0.005},${lat + 0.005}`;
    iframeSrc = `https://www.openstreetmap.org/export/embed.html?bbox=${bbox}&layer=mapnik&marker=${lat},${lon}`;
  } else {
    throw new Error("Unsupported map type. Use 'google' or 'openstreetmap'.");
  }

  return `<iframe src="${iframeSrc}" width="${width}" height="${height}" style="border:0;" allowfullscreen loading="lazy"></iframe>`;
}
</script>

<template>
  <DateTimePicker
    :showModal="showDateModal"
    :closeModal="closeDateModal"
  ></DateTimePicker>
  <div class="header-section-map">
    <label :for="id"
      >{{ label }}
      <span class="required" v-if="required">(Required)</span></label
    >
    <div class="top-right">
      <img
        src="@/assets/unschedule-icon.svg"
        alt="image"
        @click="openDateModal"
        v-if="!datePickerValue && contentExpiration"
      /><img
        src="@/assets/schedule-icon.svg"
        alt="image"
        @click="openDateModal"
        v-if="datePickerValue && contentExpiration"
      />
      <p v-if="datePickerValue">
        {{
          datePickerValue.getDate() +
          "/" +
          datePickerValue.getMonth() +
          "/" +
          datePickerValue.getFullYear() +
          "," +
          datePickerValue.getHours() +
          ":" +
          datePickerValue.getMinutes() +
          ":" +
          datePickerValue.getSeconds()
        }}
      </p>
    </div>
  </div>
  <div class="search-div">
    <input
      type="text"
      v-model="searchValue"
      class="search-input"
      @keyup.enter="searchPlace"
    />
    <button @click="searchPlace" class="search-button" id="search-button">
      <img src="@/assets/search-icon.svg" alt="Search Icon" />
      Search
    </button>
  </div>
  <div ref="mapContainer" class="map-container"></div>
</template>

<style scoped>
.map-container {
  height: 400px;
  width: 100%;
}

.label-span {
  color: blue;
  font-weight: bold;
}

.search-div {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-input {
  flex: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-right: none;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  font-size: 1rem;
}

.search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background-color: blue;
  color: white;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border: 1px solid blue;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  cursor: pointer;
}

.search-button img {
  width: 1rem;
  height: 1rem;
}

.search-button:hover {
  background-color: darkblue;
}

.required {
  color: #606060;
  font-weight: 300;
  font-size: smaller;
  margin-left: 5px;
}

.top-right {
  display: flex;
  gap: 0.5rem;
}

.header-section-map {
  display: flex;
  justify-content: space-between;
  width: 96%;
}
</style>
